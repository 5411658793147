body {
  background-color: #F4F5F5!important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tabs {
  margin: 20px auto;
  width: 95%;

}

.nav-item {
  font-weight: 700;
  font-size: 24px;
  color: #C4C4C4 !important;
  border-radius: 10px 10px 0px 0px!important;
  padding: 10px 25px 10px 25px;
  text-decoration: none!important;
  box-shadow: 5px 6px 8px rgba(0,0,0,0.02);
}

li.nav-item {
  font-weight: 700;
  font-size: 24px;
  background-color: white;
  color: #C4C4C4 !important;
  border-radius: 10px 10px 0px 0px!important;
  padding: 0px 10px 0px 10px;
  margin: 10px 10px 0px 0px;
  text-decoration: none!important;
  border: transparent;
}

.nav-link {
  font-weight: 700;
  font-size: 24px;
  color: #C4C4C4 !important;
  background-color: white;
  text-decoration: none!important; }

.nav-link.active {
  font-weight: 700;
  font-size: 24px;
  border: none!important;
  color: #AB192D!important;
  text-decoration: none!important;
}

.mb-3.nav.nav-tabs {
  border: none!important;
  margin: 0px!important;
}
.nav-link:hover {
  border: none!important;
} 

.ag-root-wrapper.ag-ltr.ag-layout-normal {
  border-radius: 0px 10px 10px 10px;
  border: none!important;
  box-shadow: 5px 6px 8px rgba(0,0,0,0.02);
}

.ag-header-container{
  margin-right: 0px!important;
  border-radius: 10px;
  padding-bottom: 10px;
  background: rgba(120, 143, 153, 0.14);
}

.ag-header-viewport {
  border-radius: 10px;
  border: solid white 4px!important;
  background: white;
}

.ag-header-row.ag-header-row-column-filter {
  margin-top: -5px;
  border-radius: 10px;
}

.site-header {
  margin-top: 0px!important;
}

/* Primary Button color override */
.btn-primary {
  background-color:#AB192D!important;
  border-color: #AB192D!important;
}
/* Primary Button color override */

/* inp-progress page */
.in-progress {
  background-color: rgb(255, 255, 255);
  text-align: center;
  height: 500px;
  margin: auto;
  padding: 50px;
}
/* inp-progress page */

/* loading spinner */
.spinner-center {
  margin: 100px 0px 0px 50%!important;
}

/* Login page buttons */
.login-button:link {
  color: white!important;
}

.login-button:visited {
  color: white!important;
}

.login-button:hover {
  color: white!important;
}

.login-button:active {
  color: white!important;
}

.login-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* margin: auto;  */
  align-items: center;
  padding:10px;
}

.body-toaster {
  z-index: 9999999!important;
  position: relative;
  padding-top: 50px;
}

/* Authorization css */

.auth-body {
  background: white!important;
  height:590px;
  display:flex;
  flex-direction: column;
  justify-content:center;
  gap:70px;
  align-items: center;
  margin: 20px auto;
  width: 95%;
  border-radius: 10px 10px 0px 0px!important;
}

/* Image viewer styles */
.react-pdf__Page__canvas {
  max-width: 100%;
  height:auto!important;
}

.react-pdf__Document {
  max-width: 100%;
  height:auto!important;
}

.react-pdf__Page__textContent {
  display: none;
}

.pdf-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

