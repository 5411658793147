div.buttonTab {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.btn-outline-danger, .btn-outline-success {
    color: #AB192D !important;
    border-color: #AB192D !important;
    height: 57px;
    width: 181px;
    border-radius: 50px;
}

/***
.btn-outline-success {
    color: #AB192D !important;
    border-color: #AB192D !important;
    height: 57px;
    width: 181px;
    border-radius: 50px;
}
    ***/

    .btn-outline-danger:hover, .btn-outline-success:hover {
        color: white !important;
        background-color: #AB192D;
    }

/* .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  } */

.sftp-body {
    background: white!important;
    height:590px;
    display:flex;
    /* flex-direction: row; */
    justify-content:space-evenly;
    align-items: center;
}

.sftp-main {
    background: white!important;
}

.arrow-style {
    font-size: 150px;
    color: #C4C4C4
    
}