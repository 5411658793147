/* .table-width {
    height: 1000px!important;
    width: 1000px!important;
} */

.ag-row-hover{
    border-radius: 10px;
    padding-bottom: 40px!important;
    background-color: rgba(120, 143, 153, 0.14)!important;

}

.ag-row {
   border: 3px solid white!important;
}

.page-size-div {
    margin-top: 10px;
}

.form-link-style, a:link {
    color: #2AAAE1 !important;
}


/** FIlter buttons*/
.btn-filter {
    color: #8C8C8C;
    background-color: #ECEFF1;
    margin-right: 5px;
}

.filter-section {
    float: right;
}

.filter-label {
    
}

.carrier-buttons {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;

}

.btn-primary{
    
    white-space: nowrap;
}




/* MOBILE CSS */

.mobile-button{
    flex: 50%;
  /*  margin-top: 10px;*/
    
  
}
.form-filer{
    flex: 50%;
    margin-top: 0px;
    
}

.carrier-name-link:link {
    color: black!important;
    background-color: transparent!important;
    text-decoration: none!important;
}

.carrier-name-link:visited {
    color: black!important;
    background-color: transparent!important;
    text-decoration: none!important;
}

.carrier-name-link:hover {
    color: black!important;
    background-color: transparent!important;
    text-decoration: underline!important;
}

.carrier-name-link:active {
    color: black!important;
    background-color: transparent!important;
    text-decoration: underline!important;
}


@media screen and (max-width: 700px) {
    .all-buttons, .form-filer {
        flex-direction: column;
        flex: 100%;
        
    }
}

@media screen and (max-width: 700px){
    .mobile-button, .clear-btn, .status-btn, .column-btn{
        margin-top: 5px;
        width:100%;

    }
    
    .sftp-body{
        margin-top: 10px;
    }
}

@media screen and (max-width: 1170px){
    .form-filer{
        margin-top: 0px;
        
    }
}
