.footer {
    margin: 10px;
}

.mta-contact-info {
    float: right;
    margin-top: -30px;
}

.copyright {
    /* align-items: center; */
    text-align: center;
    padding-top: 20vh;
} 