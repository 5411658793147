.alert {
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    color: white;
    font-size: 1.5em;
    margin: 0 auto;
    margin-bottom: 1em;
    padding: 1em;
    width: calc(100% - 100px);
}

.alert-success {
    background-color: #FFC804;
}

button:focus {
    outline: 0;
}

.close {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 2em;
    color: $grey;
    cursor: pointer;
    &:hover

{
    color: inherit;
}

}

/***
.btn {
    border-radius: 5px;
    border: solid 1px #000000;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1.5em;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1em;
    padding: 1em;
    width: 40%;
}
    ***/

.reset {
    background-color: transparent;
    border: solid 3px $grey;
    color: $grey;
    transition: background-color 0.5s linear 0.1s;
    &:hover

{
    background-color: $grey;
    color: white;
}
}
