body {
    font-family: Helvetica;
    margin: 0;
}

a {
    text-decoration: none;
    color: #000;
}

.site-header {
    width: 95%;
    margin: 20px auto;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
}

.site-identity {
    margin: 40px 0px 30px 0px!important;
}

.site-identity h1 {
    font-size: 1.5em;
    margin: .6em 0;
    display: inline-block;
}


.site-navigation ul,
.site-navigation li {
    margin: 0;
    padding: 0;
}

.site-navigation li {
    display: inline-block;
    margin: 1.4em 1em 1em 1em;
}

nav {
    height: 85px;
    font-weight: 700;
    font-size: 24px;
    color: #AB192D!important;
    border-radius: 0px 0px 10px 10px;
    padding: 0px 25px 0px 25px;
    background-color: white;
    margin-top: -30px;
    text-decoration: none!important;
    border: solid 2px #C4C4C4;
}


a {
    color:#AB192D!important;
    text-decoration: none!important;
}

.alert.alert-success {
    margin-top: 0px!important;
    margin: 20px auto;
    width: 95%;
    padding-left: 20px!important;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    border-color: transparent!important;
    color: black;
    background-color: #FFC804;
}

.navigation {
    display:flex;
    flex-direction: column;
    z-index: 9999999999;
}
.activated-nav {
    /* background: white;
    padding: 0px 25px 0px 25px!important; */
    /* height: 85px; */
    font-weight: 700;
    font-size: 24px;
    color: #AB192D!important;
    border-radius: 0px 0px 10px 10px;
    /* padding: 0px 25px 0px 25px; */
    background-color: white;
    margin-top: -30px;
    text-decoration: none!important;
    border: solid 2px #C4C4C4;
}

/* .navigation-item {
    height: 10px;
} */

.menu-button {
    background-color: white!important;
    color: #AB192D!important;
    border: solid 2px #C4C4C4!important;
    border-top: 0px!important;
    padding: 5px 27px 5px 35px;
    border-radius: 0 0 10px 10px;
    font-size: 24px;
    font-weight: 700;
    width: 200px;
}

.dropdown-toggle::after { border: none; }



/* MOBILE CSS */
/* Log In and Out Button Disable for mobile */


@media screen and (max-width: 730px){
    .menu-button{
      display: none;
    }

    .nav-link, .nav-link.active{
        font-size: 20px;
        
    }
    li.nav-item{
        border-radius: 5px !important;
    }
  }
